import { BaseRouteState, Policy, SocialReferent } from '@shared/data-access'
import { flow, types } from 'mobx-state-tree'
import { actions, getCarePersonalData } from '@sr/routes/common'
import { t } from 'i18next'

export const UserProfileRouteState = BaseRouteState.named(
  'UserProfileRouteState',
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    user: types.maybeNull(types.reference(SocialReferent)),
    isEditing: false,
  })
  .views((self) => ({
    get userPersonalData() {
      if (!self.policy) return []    
      return getCarePersonalData({
        t,
        isEditing: self.isEditing,
        ...self.policy,
        socialReferent: self.policy.socialReferent?.fullName,
        policyNumber: self.policy.policyNumber,
        contractNumber: self.policy.contractNumber,
      }).filter((val) => val)
    },
    get defaultValues() {
      if (!self.policy) return {}
      return {
        preferredLanguage: self.policy.preferredLanguage,
        sex: self.policy.sex,
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserProfileRouteState')
      try {
        const userInfo = self.loadUserInfo()
        const policy = self.loadPolicy()

        yield Promise.all([userInfo, policy])
      } finally {
        self.removeLoading('UserProfileRouteState')
      }
    }),
    setEditingMode(mode) {
      self.isEditing = mode
    },
    reload() {
      self.loadDependencies()
    },
    ...actions(self),
  }))
